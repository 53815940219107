import { Box, IconButton, ButtonGroup, TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
  AutoAwesome as PromoteIcon,
  Save as SaveIcon
} from '@mui/icons-material'
import mb from 'meatbag'
import { useState, useEffect } from 'react'

const fmt_contact = num_contacts => mb.plural(num_contacts, 'Contact', 'Contacts', num_contacts => '')

const CanvassContacts = ({ state, hub, contact_canditates }) => {
  const [editing, set_editing] = useState({ contact_id: null })
  const [adding_contact, set_adding_contact] = useState(
    contact_canditates.length > 0 ? contact_canditates[0].contact_id : ''
  )

  const delete_secondary_contact = (canvass_id, contact_id) =>
    hub.emit('delete secondary contact', { canvass_id, contact_id })

  const delete_primary_contact = (canvass_id, contact_id, new_primary_contact_id) =>
    hub.emit('delete primary contact', {
      canvass_id,
      contact_id_to_delete: contact_id,
      contact_id_to_promote: new_primary_contact_id
    })

  const promote_to_primary_contact = (canvass_id, contact_id) =>
    hub.emit('promote to primary contact', { canvass_id, contact_id })

  const set_contact_details = (contact_id, { first_name, last_name, trading_name, email_address, phone_number }) =>
    hub.emit('set contact details', { contact_id, first_name, last_name, trading_name, email_address, phone_number })

  const set_canvass_contact_retailer = (contact_id, { canvass_id, retailer_id, previous_retailer_id }) =>
    hub.emit('set canvass contact retailer', { contact_id, canvass_id, retailer_id, previous_retailer_id })

  const add_contact = () => hub.emit('add contact', { canvass_id: state.canvass.id, contact_id: adding_contact })

  const contact_details = contact => {
    return editing.contact_id != contact.id ? (
      <>
        <span>
          {contact.first_name} {contact.last_name} ({contact.trading_name})
        </span>
        {contact.email_address ? <span>{contact.email_address}</span> : <span>{'\u00A0'}</span>}
        {contact.phone_number ? <span>{contact.phone_number}</span> : <span>{'\u00A0'}</span>}
        {contact.retailer_id ? (
          <span>{state.island_retailers.find(r => r.id == contact.retailer_id)?.name}</span>
        ) : (
          <span>{'\u00A0'}</span>
        )}
      </>
    ) : (
      <>
        <span>
          <TextField
            variant='standard'
            value={`${editing.first_name ?? contact.first_name ?? ''} ${editing.last_name ?? contact.last_name ?? ''}`}
            onChange={e => {
              const [first_name, last_name] = e.target.value.split(' ')
              set_editing(s => ({ ...s, first_name, last_name }))
            }}
          />
        </span>
        <span>
          <TextField
            variant='standard'
            value={editing.trading_name ?? contact.trading_name ?? ''}
            onChange={e => set_editing(s => ({ ...s, trading_name: e.target.value }))}
          />
        </span>
        <span>
          <TextField
            variant='standard'
            value={editing.email_address ?? contact.email_address ?? ''}
            onChange={e => set_editing(s => ({ ...s, email_address: e.target.value }))}
          />
        </span>
        <span>
          <TextField
            variant='standard'
            value={editing.phone_number ?? contact.phone_number ?? ''}
            onChange={e => set_editing(s => ({ ...s, phone_number: e.target.value }))}
          />
        </span>
        <span>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id='region-select-label'>Retailer</InputLabel>
            <Select
              labelId='region-select-label'
              id='region-select'
              value={editing.retailer_id ?? contact.retailer_id ?? ''}
              onChange={e => set_editing(s => ({ ...s, retailer_id: e.target.value }))}
            >
              {state.island_retailers.map(retailer => (
                <MenuItem key={retailer.id} value={retailer.id}>
                  {retailer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
      </>
    )
  }

  const can_delete = () => state.canvass.secondary_contacts.length > 0 && editing.contact_id == null

  const can_promote = () => editing.contact_id == null

  const can_add = () => editing.contact_id == null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem',
          borderRight: '1px double'
        }}
      >
        <h2 style={{ marginBottom: 0 }}>Primary Contact</h2>
        {contact_details(state.contact)}
        <ButtonGroup variant='contained' sx={{ alignSelf: 'flex-end' }}>
          <IconButton
            size='small'
            onClick={e => {
              if (editing.contact_id == state.contact.id) {
                set_contact_details(editing.contact_id, {
                  first_name: editing.first_name ?? state.contact.first_name,
                  last_name: editing.last_name ?? state.contact.last_name,
                  trading_name: editing.trading_name ?? state.contact.trading_name,
                  email_address: editing.email_address ?? state.contact.email_address,
                  phone_number: editing.phone_number ?? state.contact.phone_number
                })

                set_canvass_contact_retailer(editing.contact_id, {
                  canvass_id: state.canvass.id,
                  retailer_id: editing.retailer_id ?? state.contact.retailer_id,
                  previous_retailer_id: state.contact.retailer_id
                })
              }
              set_editing({ contact_id: editing.contact_id == state.contact.id ? null : state.contact.id })
            }}
          >
            {editing.contact_id == state.contact.id ? <SaveIcon /> : <EditIcon />}
          </IconButton>
          <IconButton
            size='small'
            onClick={e =>
              delete_primary_contact(state.canvass.id, state.contact.id, state.canvass.secondary_contacts[0].id)
            }
            disabled={!can_delete()}
          >
            <DeleteIcon />
          </IconButton>
        </ButtonGroup>
      </Box>
      {state.canvass.secondary_contacts.map((contact, index) => (
        <Box
          key={`secondary_contact_${index}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '1rem'
          }}
        >
          <h2 style={{ marginBottom: 0 }}>
            {index == 0 ? `Secondary ${fmt_contact(state.canvass.secondary_contacts)}` : '\u00A0'}
          </h2>
          {contact_details(contact)}
          <ButtonGroup variant='contained' sx={{ alignSelf: 'flex-end' }}>
            <IconButton
              size='small'
              onClick={e => promote_to_primary_contact(state.canvass.id, contact.id)}
              disabled={!can_promote()}
            >
              <PromoteIcon />
            </IconButton>
            <IconButton
              size='small'
              // onClick={e => set_editing({ contact_id: editing.contact_id == contact.id ? null : contact.id })}
              onClick={e => {
                if (editing.contact_id == contact.id) {
                  set_contact_details(editing.contact_id, {
                    first_name: editing.first_name ?? contact.first_name,
                    last_name: editing.last_name ?? contact.last_name,
                    trading_name: editing.trading_name ?? contact.trading_name,
                    email_address: editing.email_address ?? contact.email_address,
                    phone_number: editing.phone_number ?? contact.phone_number
                  })

                  set_canvass_contact_retailer(editing.contact_id, {
                    canvass_id: state.canvass.id,
                    retailer_id: editing.retailer_id ?? contact.retailer_id,
                    previous_retailer_id: contact.retailer_id
                  })
                }
                set_editing({ contact_id: editing.contact_id == contact.id ? null : contact.id })
              }}
            >
              {editing.contact_id == contact.id ? <SaveIcon /> : <EditIcon />}
            </IconButton>
            <IconButton
              size='small'
              onClick={e => delete_secondary_contact(state.canvass.id, contact.id)}
              disabled={!can_delete()}
            >
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
      ))}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem', minWidth: 120 }}>
        <h2 style={{ marginBottom: 0 }}>{'\u00A0'}</h2>
        <span>{'\u00A0'}</span>
        <span>{'\u00A0'}</span>
        <span>{'\u00A0'}</span>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', padding: '1rem', alignItems: 'center' }}>
          <FormControl>
            <Select
              disabled={contact_canditates.length == 0}
              onChange={e => set_adding_contact(e.target.value)}
              value={adding_contact}
            >
              {contact_canditates.length == 0 && (
                <MenuItem value='None Available' key='contact-select-none'>
                  <em>None Available</em>
                </MenuItem>
              )}
              {contact_canditates.map(({ contact_id, name }) => (
                <MenuItem value={contact_id} key={`contact-select-${contact_id}`}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ButtonGroup variant='contained'>
            <IconButton size='small' disabled={!can_add()} onClick={e => add_contact()}>
              <AddIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  )
}

export default CanvassContacts
